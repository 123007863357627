import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Button, Input, Typography, Stack, Tooltip, Textarea, Modal, FormLabel, ModalClose, Select, Option } from '@mui/joy';
import { CSSTransition } from 'react-transition-group';
import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { BasicAssets } from '../../assets/basicAssets';
import { NavImages } from '../../assets/navigation/navImgs';

import '../../App.css'
import '../../css/transitions.css'

const SupportForm = ({ userID, onSubmit, result }) => {
  return (
    <Box
      sx={{
        width: '100%',  
        bgcolor: 'white', 
        boxShadow: 24, 
        p: 3, 
        borderRadius: '8px', 
        textAlign: 'center',
      }}
    >
      <form onSubmit={onSubmit}>
        <Typography level="h4" color='neutral' marginBottom={2}>Get In Touch</Typography>
        <Stack spacing={1}>
          <Stack spacing={0.5}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" required defaultValue={userID ? userID.username : ''} />
          </Stack>

          <Stack spacing={0.5}>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" required defaultValue={userID ? userID.email : ''} />
          </Stack>

          <Stack spacing={0.5} sx={{ maxWidth: '100%', overflow: 'hidden' }}>
            <FormLabel>Subject</FormLabel>
            <Select slotProps={{ listbox: { sx: { width: '100%' }}}} name="subject" required placeholder="Choose an option...">
              <Option value="Website Issue">Website Issue</Option>
              <Option value="Suggest a Correction">Suggest a Correction</Option>
              <Option value="Suggest an Addition">Suggest an Addition</Option>
              <Option value="Feature Request">Feature Request</Option>
              <Option value="General Inquiry">General Inquiry</Option>
            </Select>
          </Stack>

          <Stack spacing={0.5}>
            <FormLabel>Message</FormLabel>
            <Textarea name="message" required minRows={6} maxRows={14} />
          </Stack>

          <Button color="neutral" type="submit">Submit Form</Button>
        </Stack>
      </form>
      <Typography color='neutral'  mt={2} level='h4'>{result}</Typography>
    </Box>
  );
};

const SupportToggle = ({setDisableZoom, disableZoom}) => {
    const [supportOpen, setSupportOpen] = useState()
    const { activeIcon, setActiveIcon, userID } = usePosition()
    const [result, setResult ] = useState("");
  
    const handleMouseEnter = () => {
      setDisableZoom(true);
    };
    const handleMouseLeave = () => {
      setDisableZoom(false);
    };

    const handleSupportOpenClick = () => {
        setActiveIcon({
            search:false,
            newPin:false,
            support:!activeIcon.support,
        })
        setSupportOpen(true)
    }

    const handleSupportClose = () => {
      setSupportOpen(false)
      setActiveIcon({
        search:false,
        newPin:false,
        support:false,
    })
    setResult('')
    }

    const onSupportSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "bcaf3925-5598-40a8-afeb-8a59aee7c7c0");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully!");
        setTimeout(() => {
          event.target.reset();
          setSupportOpen(false); // Initiates exit transition
          setTimeout(() => handleSupportClose(), 1000); // Close after 1 second fade-out
        }, 3000);
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };
  
    return (
      <>
      <Box
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave} 
      >
  
      <Tooltip title={activeIcon.support ?'':'Get in Touch'} variant='solid' placement='right'>
  
      <Button  
    variant='solid' 
    sx={{
      position: 'fixed', 
      top: '195px', 
      left: '10px', 
      zIndex: 1006, 
      width: '30px', 
      height: '30px', 
      marginTop: '10px', 
      border: '1px solid grey',
      backgroundColor: 'white', 
      '&:hover': { 
        backgroundColor: 'var(--grey-hover)'
      }
    }} 
    onClick={handleSupportOpenClick}
  >
    <img src={activeIcon.support ? BasicAssets.close : NavImages.contact} style={{ height: '15px' }} alt='support'/>
  </Button>
          </Tooltip>
  
      </Box>
      <CSSTransition in={supportOpen} timeout={1000} classNames="fade" unmountOnExit>
        <Modal open={supportOpen} onClose={handleSupportClose}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: isMobile?'95vw':'400px' }}>
            <ModalClose />
            <SupportForm userID={userID} onSubmit={onSupportSubmit} result={result} />
          </Box>
        </Modal>
      </CSSTransition>
        </>
    )
  }

  export {SupportToggle, SupportForm}