import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import disclaimerImage from '../../assets/disclaimer.svg'
import { Modal, ModalDialog, Box, Typography, Input, Button, Card, Stack, FormLabel, Checkbox } from '@mui/joy';
import {  updateUserAttributes} from 'aws-amplify/auth';
import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { CSSTransition } from 'react-transition-group';
import '../../css/transitions.css'


const SpearheadWarningModal = ({ open, handleClose }) => {
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false)

  // Placeholder function to handle continue action
  const handleContinue = async () => {
    setLoading(true)
      // Display the success message for 3 seconds, then trigger CSS transition to fade out
      setTimeout(() => {
        setShowModal(false); // Initiates exit transition
        setTimeout(() => handleCloseFinal(), 1000); // Close after 1 second fade-out
      }, 1500);

  };

  const handleCloseFinal = () => {
    handleClose();
    setShowModal(true); // Reset for next open
    setLoading(false)
  };

  const handleExternalNavigation = () => {
    window.open("https://buymeacoffee.com/ullrmaps", "_blank", "noopener,noreferrer");
  };

  return (
    <CSSTransition
      in={open && showModal}
      timeout={1000} // 1 second fade-out transition
      classNames="fade"
      unmountOnExit
    >
      <Modal open={open} onClose={handleCloseFinal}>
        <ModalDialog layout="fullscreen"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}>
          <Box sx={{
            position: 'absolute',
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '110%' : '400px', 
            maxWidth: isMobile ? '400px' : '400px', 
            bgcolor: 'background.paper', 
            boxShadow: 24, 
            p: 4, 
            borderRadius: '8px', 
            textAlign: 'center',
          }}>
            <Card sx={{
              color: 'white',
              p: 2,
              height: isMobile ? '40%' : '220px',
              }}> 
              <Typography>Currently, ULLR Maps does not offer offline functionality. Ensure you have a data connection to use this map.</Typography>
                
              <Button variant='outlined' color='neutral' onClick={handleExternalNavigation}>Please consider supporting ULLR Maps to help develop this feature. </Button>

                <Stack direction='row' justifyContent={'space-evenly'} alignItems='center' >

                  <Button
                    variant='outlined'
                    color='neutral'
                    onClick={handleContinue}
                    loading={loading}
                  >
                    Continue
                  </Button>
                </Stack>

            </Card>
          </Box>
        </ModalDialog>
      </Modal>
    </CSSTransition>
  );
};

const UpdateNameModalIMPORTANT = ({ open, handleClose }) => {
    const {setUserID} = usePosition();
    const [userName, setUserName]= useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
  
    // Function to handle updating preferred username
    const handleUpdateName = async () => {
      //console.log(newUsername)
      try {
        await updateUserAttributes( {
          userAttributes: {
              'preferred_username': userName,
          }
        });
        setSuccessMessage('Name updated successfully!');
        setUserID(prevState => ({
          ...prevState,
          preferred_username:userName,
          
        }))
        setErrorMessage('');
        setUserName('');

      } catch (error) {
        setErrorMessage('Failed to update username: ' + error.message + 'please contact support');
        //console.log(error)
        setSuccessMessage('');
      }
    };
  
    const handleCloseFinal = () => {
      handleClose();
      setErrorMessage('');
      setSuccessMessage('');  
    }
  
    return (
      <Modal   open={open} onClose={handleCloseFinal}>
        <ModalDialog layout="fullscreen"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '8px', textAlign: 'center'}}>
          <Card sx={{color:'white'}}> 
          {successMessage === ''
            ? 
          <Stack> 
          <Typography variant="h6" component="h2" mb={2}>
            We have recently made some changes, please enter a username for your account.
          </Typography>
          <Stack>
                    <Stack spacing={0}>
          <FormLabel>
            <Typography level='body-xs'>Userame</Typography>
            </FormLabel>
          <Input
            label="Username"
            variant="outlined"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            sx={{ mb: 2 }}
            /> 
            </Stack>
            </Stack>
            </Stack>
      :
      <></>
      }
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          {successMessage && 
          <Stack>
            <Typography color="neutral">{successMessage}</Typography>
            <Button variant='outlined' color='neutral' onClick={handleCloseFinal}>Continue</Button>
          </Stack>
            }
  
          {successMessage === '' 
          ? 
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleUpdateName}
            disabled={(!userName)}
            sx={{ mt: 2 }}
          >
            Update Username
          </Button>
  
          :
      <></>
      }
            </Card>
        </Box>
        </ModalDialog>
      </Modal>
    );
  };



  const WaiverModalIMPORTANT = ({ open, handleClose }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(true);

    // Placeholder function to handle continue action
    const handleContinue = async () => {
      try {
        await updateUserAttributes({
          userAttributes: {
              'zoneinfo': 'accepted',
          }
        });
        setSuccessMessage('Thanks, Happy Exploring!');
        setErrorMessage('');

        // Display the success message for 3 seconds, then trigger CSS transition to fade out
        setTimeout(() => {
          setShowModal(false); // Initiates exit transition
          setTimeout(() => handleCloseFinal(), 1000); // Close after 1 second fade-out
        }, 3000);

      } catch (error) {
        setErrorMessage('Something went wrong, ' + error.message + ' please contact support');
        setSuccessMessage('');
      }
    };

    const handleCloseFinal = () => {
      setErrorMessage('');
      setSuccessMessage('');
      setIsChecked(false);
      handleClose();
      setShowModal(true); // Reset for next open
    };

    return (
      <CSSTransition
        in={open && showModal}
        timeout={1000} // 1 second fade-out transition
        classNames="fade"
        unmountOnExit
      >
        <Modal open={open} onClose={handleCloseFinal}>
          <ModalDialog layout="fullscreen"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <Box sx={{
              position: 'absolute',
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              width: isMobile ? '110%' : '450px',
              maxWidth: isMobile ? '450px' : '450px', 
              bgcolor: 'background.paper', 
              boxShadow: 24, 
              p: 4, 
              borderRadius: '8px', 
              textAlign: 'center',
            }}>
              <Card sx={{
                color: 'white',
                p: 0.5,
                height: isMobile ? '90%' : '675px',
                }}> 
                <img src={disclaimerImage} alt='disclaimer image' />
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                {successMessage && <Typography>Thanks, Happy Exploring!</Typography>}
                {!successMessage &&
                  <Stack direction='row' justifyContent={'space-evenly'} alignItems='center' mb={1.5}>
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      color='neutral'
                      label='I Accept'
                    />
                    <Button
                      variant='outlined'
                      color='neutral'
                      onClick={handleContinue}
                      disabled={!isChecked}
                    >
                      Continue
                    </Button>
                  </Stack>
                }
              </Card>
            </Box>
          </ModalDialog>
        </Modal>
      </CSSTransition>
    );
};
 


  export {UpdateNameModalIMPORTANT, WaiverModalIMPORTANT, SpearheadWarningModal}