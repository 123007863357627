import { usePosition } from "../MapUtils/MapContext/PositionContext.js"
import { Button } from "@mui/joy";
import React, { useState } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
import { useMap } from "react-leaflet";
import { isMobile } from 'react-device-detect';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext.js'

/*
const handleSearchLocator = () => {
    let locationData = {
      latitude: inputData["Latitude (N)"],
      longitude: inputData["Longitude (W)"],
      zoom: inputData['Zoom Level'],
    };
    setPosition(locationData);
  };
  */
// set single pin to main view
const GoToSinglePin= (locationData) => {
    const {setPosition} = usePosition()
    setPosition(locationData)

}

// Custom marker icon (assuming redLocation is passed as a prop or imported here)
const customIconRed = new L.Icon({
  iconUrl: '/path/to/redLocation.png', // adjust this path as needed
  iconSize: [25, 25], 
  iconAnchor: [12, 25],
});

const ToggleMultiplePins = ({ closeAll }) => {
  const { pinView, setPinView } = usePinListPosition();

  // Toggle pins function
  const togglePins = () => {
    if (pinView) {
      console.log('removin pins')
      setPinView(false)
    } else {
      console.log('displaying pins')
      setPinView(true);
      if (isMobile){
        closeAll()
      };
    }
  };

  return (
    <Button color='neutral' variant='outlined' onClick={togglePins}>
      {pinView ? 'Hide Pins' : 'View All Pins'}
    </Button>
  );
};


export {GoToSinglePin, ToggleMultiplePins}