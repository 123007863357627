import React, { createContext, useState, useContext } from 'react';

// Create a context for the position
const PositionContext = createContext();

/*
position- position of currently selected pin within the feature search system
userPosition- position of user
activeIcon - controls which icon of the left bar is active, used to ensure only one open at a time
pinlistopen- specifies that pin list is open to allow for adjusting of other ui elements
currentUserRoles- stores user role to allow for admin view
userID- store user id info

*/
// Create a provider component
export const PositionProvider = ({ children }) => {
  const [position, setPosition] = useState({ latitude: null, longitude: null, zoom: null, title:null });
  const [userPosition, setUserPosition] = useState({ latitude: null, longitude: null, zoom: null, elevation:null });
  const [ activeIcon, setActiveIcon] = useState({newPin:false, search:false, support:false})
  const [pinListOpen, setPinListOpen]= useState(false)
  const [currentUserRoles, setCurrentUserRoles] = useState([])
  const [ userID, setUserID] = useState()

  return (
    <PositionContext.Provider value={{
      position, setPosition,
      userPosition, setUserPosition,
      activeIcon, setActiveIcon,
      pinListOpen, setPinListOpen,
      currentUserRoles, setCurrentUserRoles,
      userID,  setUserID}}>
      {children}
    </PositionContext.Provider>
  );
};

// Custom hook to use the position context
export const usePosition = () => {
  return useContext(PositionContext);
};
