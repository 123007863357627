import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePosition } from '../../utils/MapUtils/MapContext/PositionContext';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


export default function DonationsPage({handleSetCurrentMap}) { 
    const {currentUserRoles} = usePosition();
    const navigate = useNavigate()

// return to home
    const handleReturn = () => {
        handleSetCurrentMap('wbcombo')
    }
// handle nav to donation link
    const handleExternalNavigation = () => {
        window.open("https://buymeacoffee.com/ullrmaps", "_blank", "noopener,noreferrer");
      };


    return (
        <Box width='100%' height='calc(100dvh - 60px)'>
            <Stack width='100%' height='100%' justifyContent='center' alignItems='center' spacing={2}>
            <Typography>Support ULLR by donating to fund server costs as well as future updates and features!</Typography>
            <Typography>More information coming soon.....</Typography>
                
<Stack direction='row' spacing={2}>
                <Button color='neutral' onClick={handleExternalNavigation}>Donate to ULLR</Button>
                <Button color='neutral' onClick={handleReturn}>Return to main page</Button>
                </Stack>
            </Stack>
        </Box>
    )
}