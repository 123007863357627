import React, {useEffect} from 'react'
import { useMap } from 'react-leaflet'

const ToggleZoomControl = ({ disableZoom }) => {
  const map = useMap();

  useEffect(() => {
    // Change cursor style globally based on disableZoom

    const handleClick = () => {
      //console.log('Map click event');
    };
    if (disableZoom) {
      //console.log('disabling Zoom')
      // Disable zoom interactions
      map.scrollWheelZoom.disable();
      map.doubleClickZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();

      // Disable dragging, touch zoom, and tap
      map.dragging.disable();
      map.touchZoom.disable();
      map.tap?.disable();  // Optional, only on mobile devices

      // Disable click events and any other interaction
      map.off('click',/*handleClick*/);

      // Change global cursor to pointer
      document.body.style.cursor = 'pointer';
    } else {
      //console.log('reenabling Zoom')
      // Re-enable zoom interactions
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();

      // Re-enable dragging, touch zoom, and tap
      map.dragging.enable();
      map.touchZoom.enable();
      map.tap?.enable();  // Optional, only on mobile devices

      // Re-enable click events
      map.on('click', handleClick);

      // Reset global cursor style
      document.body.style.cursor = '';
    }

    // Cleanup function to reset cursor when component unmounts
    return () => {
      document.body.style.cursor = '';
    };
  }, [disableZoom, map]);

  return null;
};
export {ToggleZoomControl}