import React, {useState} from 'react';
import {Typography, Box, Stack, Button, Tooltip }from '@mui/joy';
import { version, preVersion } from './localStorage/version';

export default function Footer() {

    const handleExternalNavigation = () => {
        window.open("https://buymeacoffee.com/ullrmaps", "_blank", "noopener,noreferrer");
      };

    return (
        <>
<Tooltip title='Buy Me a Beer!'>
    <Button
        sx={{
            position: 'fixed',
            bottom: '-2px',  // Adjust this to move it higher or lower from the bottom
            left: '5px',    // Adjust this to control the distance from the left side
            zIndex: 1001,
            height: 'auto',
            width: 'auto',
            padding: 0,      // Remove extra padding for a more compact button
            display: 'flex', // Use flex to align the emoji and text
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0
        }}
        variant='plain'
        color='neutral'
        onClick={handleExternalNavigation}
    >
        <Typography>🍺</Typography>
    </Button>
</Tooltip>

<Box
    sx={{
        position: 'fixed',
        zIndex: 1000,
        backgroundColor: 'white',
        left: '0',
        bottom: '0',
        width: '100%',
        borderTop: 'solid 1px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center', // Center the content horizontally
        alignItems: 'center'      // Center the content vertically
    }}
>
    <Typography textAlign='center'>ULLR Maps {preVersion} {version}</Typography>
</Box>
</>
    )
}