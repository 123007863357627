import { Box, Button, Typography, Grid, Stack, Modal, } from '@mui/joy';
import React, { useState,  } from 'react';

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet'
import {Tooltip as TooltipLeaflet} from 'react-leaflet';

import { BasicAssets } from '../assets/basicAssets';
import location from '../assets/location.png'
import loginBG from '../assets/loginBG.png'

import FeatureSearch from '../utils/userDataFetching/FeatureSearch';
import { ToggleZoomControl } from '../utils/MapUtils/MapFunctions/ToggleZoomControl';
import { usePosition } from '../utils/MapUtils/MapContext/PositionContext';

import {PinCreationToggle} from '../utils/userDataFetching/pinCreationComponent';
import {SupportToggle} from '../utils/Modals/SupportToggle';
import { CenterOnPositionFunction, TogglePinList, GPSLocatorComponent } from '../utils/MapUtils/MapFunctions/BasicMapFunctions';
//import '../App.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet-gps/src/leaflet-gps.css';
import '../App.css'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});

// custom icon for the location
const customIconRed = new L.Icon({
  iconUrl: BasicAssets.redLocation,
  iconSize: [30, 30 ], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});
const customCyanLocation = new L.Icon({
  iconUrl: BasicAssets.cyanLocation,
  iconSize: [30, 30 ], // size of the icon
  iconAnchor: [15, 30], // point of the icon which will correspond to marker's location
});

// find location
const FinderComponent = ({ setLocation }) => {
  const map = useMapEvents({

      locationfound: (location) => {
          //console.log('location found:', location);
          setLocation(location.latlng);
      },
  });

  const handleLocate = () => {
    map.locate({ setView: true, maxZoom: 16 });
    setLocation(location.latlng);
};

function onLocationFound(e) {
  var radius = e.accuracy;

  L.marker(e.latlng).addTo(map)
      .bindPopup("You are within " + radius + " meters from this point").openPopup();

  L.circle(e.latlng, {
    color: 'grey',        // border color
    fillColor: 'none',    // fill color
    fillOpacity: 0,
    stroke: 1,     // fill opacity
    radius: radius
}).addTo(map);
}

map.on('locationfound', onLocationFound);

  
  return (
    
    <Button 
      variant="s" 
      color="neutral" 
      style={{ width:'45px', height:'40px', position: 'fixed', top: '0px', right: '8px', margin: '8px', zIndex:1000}}
      onClick={handleLocate}>
        
    </Button>
  )
}
const BlankMapBody = ({handleSetCurrentMap}) => {


  return (
    <>
    <Box sx={{
      backgroundImage:`url(${loginBG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      filter: 'blur(2px)',
      position:'fixed',
      height:'calc(100dvh - 60px)',
      width:'100vw'}}/>
      <Box
        sx={{
          position:'fixed',
          height:'calc(100dvh - 60px)',
          width:'100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          }}>

        <Stack spacing={2} alignItems="center" py={2}
        sx={{
          backgroundColor:'rgba(255,255,255,0.70)',
          border: '2px solid lightgrey',
          borderRadius: '20px',
          }}>
          <Typography level='h2' >Map Selection</Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={5} sx={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button color='neutral' variant='soft' sx={{width:'150px', height:'100px', border: '1px solid lightgrey',}} onClick={()=>handleSetCurrentMap('wbcombo')}>Whistler Blackbomb Map</Button>
            </Grid>
            <Grid item xs={5} sx={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button color='neutral' variant='soft' sx={{width:'150px', height:'100px', border: '1px solid lightgrey',}} onClick={()=>handleSetCurrentMap('spearhead')}> Spearhead Map</Button>
            </Grid>
          </Grid>
        </Stack>

    </Box>
    </>
  )
}

const MapBody = ({ center, zoom, minZoom, maxZoom, maxBounds, tileLayerUrl, currentPosition, setCurrentPosition  }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const { position, setPosition, userPosition, setUserPosition } = usePosition();
  //const {userPosition, setUserPosition} = useUserPosition()
  const [disableZoom, setDisableZoom] = useState(false);


  const handleOutOfBounds = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  return (
    <>

      <MapContainer
          id='map'
          center={center}
          zoom={zoom}
          zoomControl={false}
          minZoom={minZoom}
          maxZoom={maxZoom}
          //maxBounds={maxBounds}
          attributionControl={false}
          style={{ height: 'calc(100dvh - 60px)', backgroundColor:'black' }}
          zoomSnap={0}
      >
          {/*<FinderComponent setLocation={setLocation} />*/}
          <TileLayer attribution='offline map' url={tileLayerUrl} zIndex={1}/>
          {/*below is possible base layer*/}
          {/*<TileLayer attribution='offline map' url={'https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/450/{z}/{x}/{y}.jpg'} zIndex={0} opacity={0.5}/>*/}
          <Stack spacing={1} sx={{marginLeft:'10px', width:'30px'}}>

          <GPSLocatorComponent
            setCurrentPosition={setUserPosition}
            currentPosition={userPosition}
            maxBounds={maxBounds} 
            handleOutOfBounds={handleOutOfBounds}
            setDisableZoom={setDisableZoom}
            />
          <FeatureSearch centerFunction={setPosition} setDisableZoom={setDisableZoom} />
          <PinCreationToggle setDisableZoom={setDisableZoom} disableZoom={disableZoom}/>
          <SupportToggle setDisableZoom={setDisableZoom} disableZoom={disableZoom}/>
          {/*below is position of feature*/}
          {position.latitude && position.longitude && (
            <Marker position={[position.latitude, position.longitude]} icon={customCyanLocation}>
              <TooltipLeaflet offset={[-10, -20]} direction='left'>
                {position.title}
              </TooltipLeaflet>
            </Marker>
          )}
          {/*below is position of user*/}
          {userPosition?.latitude && userPosition?.longitude && (
            <Marker position={[userPosition.latitude, userPosition.longitude]} icon={customIconRed} />
          )}
          {/*<CenterOnPositionComponent currentPosition={currentPosition} />*/}
          <CenterOnPositionFunction setDisableZoom={setDisableZoom}/>
          <TogglePinList setDisableZoom={setDisableZoom}/>
          <ToggleZoomControl disableZoom={disableZoom} />
          </Stack>
          
      </MapContainer>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: 4,
            margin: '20vw',
            boxShadow: 24,
            textAlign: 'center',
            zIndex:1005,
            
          }}
        >
          <Stack spacing={3}
          sx={{
            display:'flex',
            alignItems:'center'
          }}>
          <Stack spacing={1}>
            <Typography level="h4">Out of Bounds</Typography>
            <Typography>Your current location is outside of the selected map.</Typography>
          </Stack>
          <Button onClick={handleCloseModal}>Close</Button>
          </Stack>
        </Box>
      </Modal>
      </>
  );
};

const BlackcombMapBody = () => {

  //const [currentPosition, setCurrentPosition] = useState({ latitude: null, longitude: null });
  
  return (
  <MapBody
      center={[50.1053056, -122.9276945]}
      zoom={14}
      minZoom={5}
      maxZoom={18}
      maxBounds={[[50.0668, -122.961], [50.123, -122.8545]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/blackcomb/bctiles/{z}/{x}/{y}.jpg'
  />
)};

const WhistlerMapBody = () => {
  
  return (
  <MapBody

      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={14}
      maxZoom={18}
      maxBounds={[[50.032569806932216, -122.85527951187242], [50.12421751908952, -123.0106465141981]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/300/{z}/{x}/{y}.jpg'
  />
)};

const SpearheadMapBody = () => {
  
  return (
  <MapBody
      center={[50.0504331639911, -122.87196525397562]}
      zoom={13}
      minZoom={13}
      maxZoom={17}
      maxBounds={[[49.98292691723076, -123.01133478052596], [50.10811971405545, -122.75551127830491]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/spearhead/shtiles/{z}/{x}/{y}.jpg'
  />
)};

const WB512300 = () => {
  
  return (
  <MapBody

      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.032569806932216, -122.85527951187242], [50.12421751908952, -123.0106465141981]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/300/{z}/{x}/{y}.jpg'
  />
)};

const WB512450 = () => {

  return (
  <MapBody

      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.032569806932216, -122.85527951187242], [50.12421751908952, -123.0106465141981]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/512/450/{z}/{x}/{y}.jpg'
  />
)};

const WB1024300 = () => {
  
  return (
  <MapBody
      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.032569806932216, -122.85527951187242], [50.12421751908952, -123.0106465141981]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/1024/300/{z}/{x}/{y}.jpg'
  />
)};

const WB1024450 = () => {

  return (
  <MapBody

      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.032569806932216, -122.85527951187242], [50.12421751908952, -123.0106465141981]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/1024/450/{z}/{x}/{y}.jpg'
  />
)};

const WBComboMapBody = () => {

  return (
  <MapBody
      center={[50.063541599446594, -122.94765848949606]}
      zoom={14}
      minZoom={13}
      maxZoom={18}
      maxBounds={[[50.03312804348003, -123.00975301129121], [50.12383877345848, -122.85537388216964]]}
      tileLayerUrl='https://s3mapdatastoragebucket82d4a-dev.s3.amazonaws.com/mapData/wb/wbtiles-1024/{z}/{x}/{y}.jpg'
  />
)};

export{ BlackcombMapBody,
  SpearheadMapBody,
  WhistlerMapBody,
  WBComboMapBody,
  BlankMapBody,
  WB512300,
  WB512450,
  WB1024300,
  WB1024450,
};
