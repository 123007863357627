import React, { useState, useEffect, useCallback } from 'react';
import { Input, Box, Button, Typography, Card, Stack, Tooltip, Modal, ModalClose, Grid, Divider } from '@mui/joy';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { isMobile, isBrowser, BrowserView, MobileView } from 'react-device-detect';

import { usePosition } from '../MapUtils/MapContext/PositionContext';
import { usePinListPosition } from '../MapUtils/MapContext/PinListContext';

import { BasicAssets } from '../../assets/basicAssets';
import { green, blue, black, doubleblack, gondola, locationpoi, mountain, people, skilift, parking} from '../../assets/categories/category'

import '../../App.css'

const ImageFilter = (Category, Classification) => {
  if (Category === 'Lift') {
    if (Classification.toLowerCase().includes('quad') || Classification.toLowerCase().includes('chair')  ) {
      return skilift
    } else if (Classification.toLowerCase().includes('gondola')) {
      return gondola
    } else if (Classification === 'T-Bar' || Classification === 'Magic Carpet') {
      return people
    } else {
      return skilift
    }
} else if (Category === 'Run') {
  if (Classification === 'Beginner') {
    return green
  } else if (Classification === 'Intermediate') {
    return blue
  } else if (Classification === 'Advanced') {
    return black
  } else if   (Classification === 'Expert') {
    return doubleblack
  } else {
    return locationpoi
  }
  } else if (Category.toLowerCase().includes('peak') || Category.toLowerCase().includes('backcountry') || Category.toLowerCase().includes('zone')) {
    return mountain
  } else if (Category === 'Point of Interest') {
    return locationpoi
  } else if (Category === 'Parking') {
    return parking
  } else {
    return locationpoi
  }
}

const fieldLabels = {
  //'Feature Name': 'Feature Name',
  'Alt Name': 'Alternative Name',
  
  'Category': 'Category',
  //'Latitude (N)': 'Latitude (N)',
  //'Longitude (W)': 'Longitude (W)',
  //Map: 'Map',
  Mountain: 'Mountain',
  Range: 'Range',
  'Top Elevation (m)': 'Top Elevation (m)',
  'Vertical Rise (m)': 'Vertical Rise (m)',
  'Length (KM)': 'Length (KM)',
  'Uphill Capacity/hr': 'Uphill Capacity/hr',
  'Ride Time (min)': 'Ride Time (min)',
  'Primary Aspect': 'Primary Aspect',
  'Classification': 'Classification',
  'Groomed / Not Groomed': 'Groom Status',
  Condition: 'Condition',
  //Information: 'Information',
  //History: 'History',
};

const FullSearchCard = ({ inputData, open, handleClose, handleSearchLocator, setFullSearchDataDisplay, handleSearchBarOpen, setDisableZoom,handleMouseEnter,  handleMouseLeave}) => {
  
  const handleModalClose = () => {
    setFullSearchDataDisplay(false)  
    handleSearchBarOpen()
    console.log('set disable on close, full')
    setDisableZoom(false);
  }

  const handleMobileSearchLocator = () => {
    handleSearchLocator()
    handleModalClose()
  }

  /*
  const handleMouseEnter = () => {
    console.log('mouse enter full')
    setDisableZoom(true);
  };

  const handleMouseLeave = () => {
    console.log('mouse leave full')
    setDisableZoom(false);
  };
*/
  return (
    <>
    
    <BrowserView>
    <Box width={'25vw'} maxWidth={'500px'}
    sx={{
      border: '1px solid grey',}}
    >
      <Card
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave} 
        sx={{
          padding: 2,
          width: '29vw',
          maxWidth:'500px',
          height: '85vh',
          position: 'fixed',
          top: '70px',
          left: 'clamp(0px, 31.5vw, 560px)',
          //transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1011,
          border: '1px solid grey'
        }}
      >
        <Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <img src={ImageFilter(inputData['Category'], inputData['Classification'])} style={{ width: '40px' }} alt="feature icon" />
            <Typography level="h3" >
              {inputData['Feature Name']}
            </Typography>
          <Button
              variant='outlined'
              sx={{
                '&:hover': { backgroundColor: 'rgba(211, 211, 211, 0.2)' },
                zIndex: 1006,
                backgroundColor: 'white',
                width: '20px',
                height: '20px'
              }}
              onClick={handleSearchLocator}
            >
              <img style={{ width: '20px', height: '18px' }} src={BasicAssets.cyanLocation} alt="location icon" />
            </Button>
            <ModalClose onClick={handleClose}></ModalClose>
          </Stack>
        <Box
          sx={{
            flex: 1, // Allow the Box to take up the remaining space
            overflowY: 'auto',
            overflowX: 'hidden',
            //border: '1px solid grey',
            //borderRadius: '5px',
          }}
        >
          <Box  sx={{ marginBottom: 1 }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid xs={4}>
                <Stack spacing={0}>
                <Typography level="body-xs"  fontWeight="bold">
                    Coordinates:
                  </Typography>
                  <Typography level="body-sm" >
                    {inputData['Latitude (N)']}, {inputData['Longitude (W)']}
                  </Typography>
                  </Stack>
                </Grid>
          {Object.keys(fieldLabels).map((key, index) => {
            if (inputData[key]) {
              return (
                <Grid xs={4} key={index}>
                  <Typography level="body-sm"  fontWeight="bold">
                    {fieldLabels[key]}
                  </Typography>
                  <Typography level="body-sm" >
                    {inputData[key]}
                  </Typography>
                </Grid>
              );
            }
            return null;
          })}
          </Grid>
          <Divider sx={{marginTop:'10px', marginBottom:'10px'}}></Divider>
          {inputData.Information? 
          <>
          <Typography level="body-sm"  fontWeight="bold">Information</Typography>
            <Typography level="body-sm" >{inputData.Information}</Typography>
            </> :
            <></>}
            {inputData.History? 
          <>
          <Typography level="body-sm"  fontWeight="bold">History</Typography>
            <Typography level="body-sm" >{inputData.History}</Typography>
            </> :
            <></>}
          </Box>
        </Box>
      </Card>
    </Box>
    </BrowserView>

    <MobileView>
      <Modal
        open={open}
        onClose={handleModalClose}
        layout="center"
        >
      <Card
        sx={{
          padding: 2,
          width: '90vw',
          height: '90vh',
          position: 'fixed',
          top: '5vh',
          left: '5vw',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1011,
        }}
      >
        <Stack />
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <img src={ImageFilter(inputData['Category'], inputData['Classification'])} style={{ width: '40px' }} alt="feature icon" />
          <Typography level="h2">
            {inputData['Feature Name']}
          </Typography>
          <Button
              variant='outlined'
              sx={{
                '&:hover': { backgroundColor: 'rgba(211, 211, 211, 0.2)' },
                zIndex: 1006,
                backgroundColor: 'white',
                width: '20px',
                height: '20px'
              }}
              onClick={handleMobileSearchLocator}
            >
              <img style={{ width: '20px', height: '18px' }} src={BasicAssets.cyanLocation} alt="location icon" />
            </Button>
          <ModalClose onClick={handleModalClose} />
        </Stack>
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Box >
              <Stack direction='row' justifyContent='flex-start' spacing={0.5}>
                <Typography level="body-sm"  fontWeight="bold">
                  Coordinates: 
                </Typography>
                <Typography level="body-sm" >
                   {' '+ inputData['Latitude (N)']}, {inputData['Longitude (W)']}
                </Typography>
                </Stack>
                <Divider sx={{my:2}}></Divider>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Object.keys(fieldLabels).map((key, index) => (
                inputData[key] ? (
                  <Grid item xs={6} key={key + index}>
                    <Stack spacing={-0.5}>
                    <Typography level="body-sm"  fontWeight="bold">
                      {fieldLabels[key]}
                    </Typography>
                    <Typography level="body-sm" >
                      {inputData[key]}
                    </Typography>
                    </Stack>
                  </Grid>
                ) : null
              ))}
              
            </Grid>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            {inputData.Information && (
              <>
                <Typography level="body-sm"  fontWeight="bold">Information</Typography>
                <Typography level="body-sm" >{inputData.Information}</Typography>
              </>
            )}
            {inputData.History && (
              <>
                <Typography level="body-sm"  fontWeight="bold">History</Typography>
                <Typography level="body-sm" >{inputData.History}</Typography>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </Modal>
  </MobileView>
    </>
  );
};

const MiniSearchCard = ({ inputData, isOpen, handleOpen, handleClose, setDisableZoom, handleSearchBarOpen }) => {
  const { setPosition } = usePosition();

  const handleMouseEnter = () => {
    //console.log('mouse enter mini')
    setDisableZoom(true);
  };

  const handleMouseLeave = () => {
    //console.log('mouse leave mini')
    setDisableZoom(false);
  };
  
  const handleSearchLocator = () => {
    //console.log(inputData)
    let locationData = {
      latitude: inputData["Latitude (N)"],
      longitude: inputData["Longitude (W)"],
      zoom: inputData['Zoom Level'],
      title:inputData['Feature Name']
    };
    //console.log({locationData})
    setPosition(locationData);
  };

  const handleDisplayFullData = () => {
    if (isOpen) {
      handleClose();  // Close this card if it's already open
    } else {
      handleOpen();
        // Open this card
        if (!isMobile) {
        handleSearchLocator()
        }
    }
  };

  return (
    <>
      <Box sx={{ borderRadius: '10px' }}>
        <Box backgroundColor='white' p={0.5}
          onClick={handleDisplayFullData}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            position: 'relative',
            borderRadius: '10px',
            width:isBrowser?'25vw':'85vw',
            '::after': {
              content: '""',
              borderRadius: '10px',
              position: 'absolute',
              bottom: 0,
              left: '2.5%',
              width: '95%',
              borderBottom: '1px solid rgb(211, 211, 211)',
            },
            '&:hover': { backgroundColor: 'rgba(211, 211, 211, 0.2)' },
          }}>
          <Stack direction='row' alignItems='center' spacing={2} p={0.5}>
            {/* Image on the left */}
            <img src={ImageFilter(inputData['Category'], inputData['Classification'])} style={{ width: '40px' }} alt="feature icon" />

            {/* Stack for Category and Feature Name, aligned to the left */}
            <Stack direction='column' spacing={0} flexGrow={1}>
              <Typography level='body-sm'>{inputData.Category}</Typography>
              <Typography level='h4'>{inputData["Feature Name"]}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>

      {isOpen && (
        <FullSearchCard
          inputData={inputData}
          open={isOpen}
          handleClose={handleClose}
          centerFunction={setPosition}
          handleSearchLocator={handleSearchLocator}
          setFullSearchDataDisplay={handleClose}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          handleSearchBarOpen={handleSearchBarOpen}
          setDisableZoom={setDisableZoom}
        />
      )}
    </>
  );
};
  
  const SearchComponent = ({ map, setDisableZoom }) => {
    const [openCardId, setOpenCardId] = useState(null);
    const { setPosition,setActiveIcon, activeIcon } = usePosition();
    const {setFocusedPin} = usePinListPosition();
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOpenCard = (id) => {
      setOpenCardId(id);  // Set the id of the card that should be open
    };
  
    const handleCloseCard = () => {
      setOpenCardId(null);
      if (isBrowser) {
        setPosition({ latitude: null, longitude: null, zoom: null, title:null })
        //console.log('zoom enabled')
        setDisableZoom(false)
      }  // Close all cards by setting the open card id to null
    };

    const handleMouseEnter = () => {
      setDisableZoom(true);
    };
  
    const handleMouseLeave = () => {
      setDisableZoom(false);
    };


  
    const handleSearch = async (query) => {
      try {
        setLoading(true)
        setOpenCardId(null)
        const response = await axios.get('https://r1w42q1gc1.execute-api.us-east-1.amazonaws.com/dev/search', {
          params: {
            query: query
          }
        });
        setResults(response.data);
        //console.log('searchresults')
        //console.log(response.data)
      } catch (error) {
        console.error('below is the error');
        console.error(error);
      }finally {
        setLoading(false); // Set loading to false after the request
      }
    };
    const handleSearchBarOpen =() => {
      if (activeIcon.search && isBrowser) {
        setPosition({ latitude: null, longitude: null, zoom: null, title:null })
      }
      setActiveIcon({newPin:false, search:!activeIcon.search, support:false})
      if (isMobile) {
        setDisableZoom(!activeIcon.search)
        
      }
      setFocusedPin(null)
      


        //console.log({results})
    }
  
    const debouncedSearch = useCallback(debounce(handleSearch, 100), []);

    useEffect(() => {
      if (searchQuery.trim()) {
        debouncedSearch(searchQuery.toLowerCase());
      } else {
        setResults([]);
      }
    }, [searchQuery, debouncedSearch]);
  
    return (
        <>
        <Stack direction='row'
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave} 
        >

        <Tooltip title={!activeIcon.search?'Feature Search':''} variant='solid' placement='right'>

        <Button loading={loading? true:false } variant='solid' 
          sx={{
            position: 'fixed', 
            top: '105px', 
            left: '10px', 
            zIndex: 1006, 
            width: '30px', 
            height: '30px', 
            marginTop: '10px', 
            border: '1px solid grey',
            backgroundColor: 'white', 
            '&:hover': { 
              backgroundColor: 'var(--grey-hover)'
            }
          }} 
          onClick={(handleSearchBarOpen)}>
          {!loading &&<img src={ activeIcon.search? BasicAssets.close:BasicAssets.search} style={{height:'15px'}} alt='Search Icon'/>}
        </Button>
            </Tooltip>
        {
            activeIcon.search ? 
        
      <Box zIndex='1005'  
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '10px',
          marginLeft:'40px',
          maxHeight:isBrowser?'85vh':'',
          width:isBrowser? '30vw' : '85vw',
          maxWidth:isBrowser? '500px':'85vw',
          border: '1px solid grey',
          
        }}>
        <Input
          variant='solid'
          color='white'
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search for a feature..."
          sx={{ marginBottom: '5px', width:isBrowser?'24vw':'80vw', maxWidth:isBrowser? '480px':'85vw',}}
        />
        {results && results.length > 0 ?
        <Box sx={{ maxHeight: isBrowser?'80vh':'75vh', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{            
            flex: 1,
            overflowY: 'scroll',
            overflowX: 'hidden',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius:'5px',
            maxHeight:isBrowser? 'calc(85vh - 60px)':''
          }}
          >
          <Stack spacing={0}>
            {results.map((results, index) => (
              <MiniSearchCard
              key={index}
              inputData={results}
              isOpen={openCardId === index}  // Pass whether this card is open
              handleOpen={() => handleOpenCard(index)}
              handleClose={handleCloseCard}
              centerFunction={setPosition}
              handleSearchBarOpen={handleSearchBarOpen}
              setDisableZoom={setDisableZoom} />
              
            ))}
            
          </Stack>
        </Box>
       </Box>
       :
       <></>
          }
      </Box>
      :
<></>
      } 
      </Stack>
      </>
    );
  };
  
  export default SearchComponent;