import {View, Image, useTheme, Text} from '@aws-amplify/ui-react'
import largeLogo from '../assets/ULLRLogo.svg'
import '../css/navbar.css'


import { ThemeProvider, createTheme, defaultTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Typography from '@mui/joy/Typography';
/*
https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
Use the above for customization of Login Screen
*/
const darkGrey = '#2c3332'
const midGrey='#5A5A5A'
const lightGrey='#E5E5E6'

const myTheme = createTheme({
  name: 'custom-theme',
  
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#2c3332',  
          80: '#2c3332',  
        },
      },
    },
    components: {
      button: {
        _hover: {
          //color: { value: '#C71AB6' }, // Hover color for the link
          backgroundColor: { value: lightGrey }, // Hover state color
          color: { value: darkGrey },
          borderColor: { value: darkGrey },
        },
        _active: {
          backgroundColor: { value: lightGrey },
        },
        _focus: {
          backgroundColor: { value: lightGrey },
        },
        primary: {
          backgroundColor: { value: darkGrey }, // Button background color
          color: { value: '#FFFFFF' }, // Button text color,
          _hover: {
            backgroundColor: { value: lightGrey },
            color: { value: darkGrey } // Hover state color
          },
          _active: {
            backgroundColor: { value: lightGrey },
          },
        },
        link: {
          color: { value: midGrey }, // Link text color
          backgroundColor: { value: 'white' },
          _hover: {
            //color: { value: '#C71AB6' }, // Hover color for the link
            backgroundColor: { value: darkGrey }, // Hover state color
            color: { value: 'white' }
          },
          _active: {
            backgroundColor: { value: lightGrey },
          },
        },
      },
      tabs: {
        borderColor: { value: 'white' },
        item: {
          color: { value: '#636364' },
          backgroundColor:{value:'#E5E5E6'},
          _hover: {
            color: { value: 'black' },
          },
          _focus: {
            color: { value: 'black' },
          },
          _active: {
            color: { value: 'black' },
            borderColor: { value: 'black' },
            backgroundColor: {value: 'white' },
          },
        },
      },
      
      input: {
        backgroundColor: { value: darkGrey },
        color: { value: darkGrey },
      },
      fieldcontrol: {
        borderColor: { value: darkGrey },
      },
      
    },
  },
});

const FormFields = {
  signUp: {
    preferred_username: {
      label: 'Username',
      placeholder: 'Enter your Username',
      order: 1,
    },
    email: {
      label: 'Email Address',
      placeholder: 'Enter your email',
      order: 2,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large} backgroundColor={'white'} >
      <img className='largeLogo' src={largeLogo}/>
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large} backgroundColor={'white'} >
        <Text color={tokens.colors.neutral[80]}>
          &copy; Copyright ULLR Maps Ltd. 2024
        </Text>
      </View>
    );
  },
}

export { components, myTheme, FormFields}